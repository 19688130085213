import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSchool, faLaptopCode, faMicroscope, faUniversity, faGraduationCap, faCertificate } from '@fortawesome/free-solid-svg-icons';

const EducationSection = styled.section`
  min-height: 100vh;
  padding: 100px 20px;
  background-color: #f3f7fa;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 3rem;
  color: #2980b9;
  font-weight: 600;
  margin-bottom: 50px;
  font-family: 'Poppins', sans-serif;
`;

const SchoolCard = styled(motion.div)`
  background-color: #fff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 30px;
  margin: 20px 0;
  max-width: 800px;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
`;

const Icon = styled.div`
  font-size: 2.5rem;
  color: #3498db;
  margin-right: 20px;
`;

const SchoolDetails = styled.div`
  flex: 1;
`;

const SchoolName = styled.h3`
  font-size: 1.75rem;
  color: #2c3e50;
  font-family: 'Poppins', sans-serif;
`;

const DateRange = styled.p`
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 1rem;
  color: #7f8c8d;
`;

const Education = () => {
  return (
    <EducationSection id="education">
      <Title>Education</Title>

      <SchoolCard
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <Icon>
          <FontAwesomeIcon icon={faSchool} />
        </Icon>
        <SchoolDetails>
          <SchoolName>West Windsor Plainsboro High School North</SchoolName>
          <DateRange>September 2019 - Present</DateRange>
          <Description>
            High School Senior with an impressive academic standing of <strong>GPA: 3.77/4.0 (UW), 4.58/5.0 (W)</strong>. 
            Focused on advanced STEM coursework, including Calculus, Advanced Algorithms, and Computer Science.
            Participated in numerous academic clubs and extracurriculars, developing leadership skills through involvement in Student Government and STEM-related activities.
          </Description>
        </SchoolDetails>
      </SchoolCard>

      <SchoolCard
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <Icon>
          <FontAwesomeIcon icon={faGraduationCap} />
        </Icon>
        <SchoolDetails>
          <SchoolName>Mercer County Community College</SchoolName>
          <DateRange>June 2023 - Present</DateRange>
          <Description>
            Concurrent Enrollment Student pursuing dual Associate in Science degrees in both <strong>Mathematics</strong> and <strong>Computer Science</strong>. 
            Achieved a <strong>GPA of 4.0/4.0</strong>. Gaining expertise in advanced mathematical theories, Linear Algebra, Calculus III, and Computer Science principles such as Algorithm Development and Data Structures.
            This additional academic experience demonstrates an eagerness to excel beyond high school-level learning and further develop technical problem-solving skills.
          </Description>
        </SchoolDetails>
      </SchoolCard>

      <SchoolCard
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <Icon>
          <FontAwesomeIcon icon={faCertificate} />
        </Icon>
        <SchoolDetails>
          <SchoolName>Google Data Analytics Career Certificate</SchoolName>
          <DateRange>January 2022 - September 2022</DateRange>
          <Description>
            Earned the prestigious <strong>Google Data Analytics Career Certificate</strong> with a perfect <strong>GPA: 4.0/4.0</strong>. 
            Developed proficiency in <strong>data analysis, statistical techniques, and visualization tools</strong> like SQL, Tableau, and R. 
            Completed real-world projects involving datasets, including data cleaning, model building, and creating impactful visual reports to drive business decisions.
          </Description>
        </SchoolDetails>
      </SchoolCard>

      <SchoolCard
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <Icon>
          <FontAwesomeIcon icon={faUniversity} />
        </Icon>
        <SchoolDetails>
          <SchoolName>Columbia University Science Honors Program (SHP)</SchoolName>
          <DateRange>November 2023 - Present</DateRange>
          <Description>
            Selected for the competitive <strong>Columbia University Science Honors Program</strong>, an advanced program for exceptional high school students. 
            Engaged in <strong>cutting-edge research</strong> in fields such as <strong>quantum computing, bioengineering, and nanotechnology</strong>. 
            Collaborating with top professors and mentors to conduct complex projects in computational models and biomedical devices.
          </Description>
        </SchoolDetails>
      </SchoolCard>

      <SchoolCard
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <Icon>
          <FontAwesomeIcon icon={faMicroscope} />
        </Icon>
        <SchoolDetails>
          <SchoolName>MIT Data Science & Machine Learning Certificate Program</SchoolName>
          <DateRange>October 2022 - March 2023</DateRange>
          <Description>
            Completed the rigorous <strong>MIT Data Science & Machine Learning Certificate Program</strong> with a <strong>GPA of 582/600</strong>. 
            Gained hands-on experience in <strong>machine learning algorithms, data modeling, and predictive analysis</strong>. 
            Implemented solutions to real-world data problems through advanced Python libraries like TensorFlow and PyTorch.
          </Description>
        </SchoolDetails>
      </SchoolCard>

      <SchoolCard
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <Icon>
          <FontAwesomeIcon icon={faLaptopCode} />
        </Icon>
        <SchoolDetails>
          <SchoolName>Purdue University - Data Science Certificate</SchoolName>
          <DateRange>October 2022 - Present</DateRange>
          <Description>
            Engaged in Purdue University’s specialized <strong>Data Analytics, Artificial Intelligence, and Machine Learning</strong> Certificate Program. 
            Focused on <strong>predictive modeling, AI applications, and large-scale data analysis</strong>. This program equips students with the skills to solve complex problems using advanced data science techniques.
          </Description>
        </SchoolDetails>
      </SchoolCard>

    </EducationSection>
  );
};

export default Education;
