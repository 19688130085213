import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const ProjectsSection = styled.section`
  min-height: 100vh;
  padding: 100px 20px;
  background-color: #f0f4f8;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 3rem;
  color: #2980b9;
  font-weight: 600;
  margin-bottom: 50px;
  font-family: 'Poppins', sans-serif;
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  width: 100%;
  max-width: 1200px;
`;

const ProjectCard = styled(motion.div)`
  background-color: #fff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.05);
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const ProjectContent = styled.div`
  padding: 20px;
  text-align: left;
`;

const ProjectTitle = styled.h3`
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 10px;
`;

const ProjectDescription = styled.p`
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 10px;
`;

const ProjectTags = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

const Tag = styled.span`
  background-color: #3498db;
  color: white;
  font-size: 0.9rem;
  padding: 5px 10px;
  border-radius: 5px;
`;

const Projects = () => {
  return (
    <ProjectsSection id="projects">
      <Title>Projects</Title>

      <ProjectsGrid>
        <ProjectCard
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <ProjectImage src="https://via.placeholder.com/600x400" alt="Mental Health App" />
          <ProjectContent>
            <ProjectTitle>Titi - Mental Health App</ProjectTitle>
            <ProjectDescription>
              Developed an AI-powered mental health app that offers an <strong>AI-driven chatbot</strong>, well-being tools, and progress tracking features. Gamified mental health care to create a welcoming experience, moving away from traditional serious tones. The app has helped over <strong>1500 users</strong> in their mental wellness journey.
            </ProjectDescription>
            <ProjectTags>
              <Tag>React Native</Tag>
              <Tag>AI</Tag>
              <Tag>Mental Health</Tag>
            </ProjectTags>
          </ProjectContent>
        </ProjectCard>

        <ProjectCard
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <ProjectImage src="https://via.placeholder.com/600x400" alt="Algorithmic Trading" />
          <ProjectContent>
            <ProjectTitle>Algorithmic Trading</ProjectTitle>
            <ProjectDescription>
              Built <strong>machine learning-based algorithms</strong> to automate trading, resulting in a profitable portfolio with a <strong>6% annual return</strong>. Utilized Python, Pinescript, and backtesting techniques to optimize trading strategies and create real-time simulations.
            </ProjectDescription>
            <ProjectTags>
              <Tag>Python</Tag>
              <Tag>Pinescript</Tag>
              <Tag>Machine Learning</Tag>
            </ProjectTags>
          </ProjectContent>
        </ProjectCard>

        <ProjectCard
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <ProjectImage src="https://via.placeholder.com/600x400" alt="MapMatch App" />
          <ProjectContent>
            <ProjectTitle>MapMatch App</ProjectTitle>
            <ProjectDescription>
              Designed a social exploration app, encouraging users to discover new locations by <strong>tracking real-time movements</strong> and exploring hidden gems. Integrated features like location sharing and random exploration prompts to foster social interactions and global exploration.
            </ProjectDescription>
            <ProjectTags>
              <Tag>React Native</Tag>
              <Tag>Mobile App</Tag>
              <Tag>GPS</Tag>
            </ProjectTags>
          </ProjectContent>
        </ProjectCard>

        <ProjectCard
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <ProjectImage src="https://via.placeholder.com/600x400" alt="Shinkansen Train" />
          <ProjectContent>
            <ProjectTitle>Shinkansen Train Predictive Analysis</ProjectTitle>
            <ProjectDescription>
              Developed predictive analysis models for a <strong>Shinkansen Train dataset</strong> during the MIT Hackathon, achieving a <strong>top 16 placement</strong> out of hundreds of participants. The model was 0.7% away from achieving the first-place accuracy, utilizing complex machine learning techniques.
            </ProjectDescription>
            <ProjectTags>
              <Tag>Data Science</Tag>
              <Tag>Predictive Modeling</Tag>
              <Tag>Python</Tag>
            </ProjectTags>
          </ProjectContent>
        </ProjectCard>

        <ProjectCard
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <ProjectImage src="https://via.placeholder.com/600x400" alt="Data Analytics" />
          <ProjectContent>
            <ProjectTitle>Real-World Data Analytics Projects</ProjectTitle>
            <ProjectDescription>
              Applied <strong>predictive analytics and machine learning</strong> to solve real-world problems, such as <strong>PIMA Diabetes Analysis</strong> and <strong>Movie Recommendation Systems</strong>. Analyzed large datasets using techniques like regression and network models to provide actionable insights.
            </ProjectDescription>
            <ProjectTags>
              <Tag>Data Analysis</Tag>
              <Tag>Machine Learning</Tag>
              <Tag>Python</Tag>
            </ProjectTags>
          </ProjectContent>
        </ProjectCard>

      </ProjectsGrid>
    </ProjectsSection>
  );
};

export default Projects;
