import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faUsers, faGavel, faBrain, faCode } from '@fortawesome/free-solid-svg-icons';

const ExperienceSection = styled.section`
  min-height: 100vh;
  padding: 100px 20px;
  background-color: #f7f9fc;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 3rem;
  color: #2980b9;
  font-weight: 600;
  margin-bottom: 50px;
  font-family: 'Poppins', sans-serif;
`;

const ExperienceCard = styled(motion.div)`
  background-color: #fff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 30px;
  margin: 20px 0;
  max-width: 800px;
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
`;

const Highlight = styled.span`
  font-weight: bold;
  color: #2980b9;
`;

const Icon = styled.div`
  font-size: 2.5rem;
  color: #3498db;
  margin-right: 20px;
`;

const ExperienceDetails = styled.div`
  flex: 1;
`;

const CompanyName = styled.h3`
  font-size: 1.75rem;
  color: #2c3e50;
  font-family: 'Poppins', sans-serif;
`;

const DateRange = styled.p`
  font-size: 1rem;
  color: #7f8c8d;
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #555;
`;

const Experience = () => {
  return (
    <ExperienceSection id="experience">
      <Title>Experience</Title>

      <ExperienceCard
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <Icon>
          <FontAwesomeIcon icon={faBriefcase} />
        </Icon>
        <ExperienceDetails>
          <CompanyName>Research & Development Intern @ The Metropolitan Museum of Art</CompanyName>
          <DateRange>May 2023 - August 2023</DateRange>
          <Description>
          Designed and presented ‘<Highlight>Traveling Through the Times,</Highlight>’ a virtual 3D exhibition connecting art with mental health and representation.<br /><br />
          Conducted research and development with the DEPILL team, exploring innovative intersections between art, technology, and community engagement.<br /><br />
          Secured <Highlight>over $400M in grants</Highlight> for 40+ cultural institutions by preparing application materials and curating a database of NYC-affiliated political contacts.<br /><br />
          Created social media graphics for @metteens, contributing to a <Highlight>40% follower increase</Highlight> during the #TeensTakeTheMet campaign.
          </Description>
        </ExperienceDetails>
      </ExperienceCard>

      <ExperienceCard
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <Icon>
          <FontAwesomeIcon icon={faUsers} />
        </Icon>
        <ExperienceDetails>
          <CompanyName>Student Representative @ West Windsor Township's Human Relations Council</CompanyName>
          <DateRange>2023 - Present</DateRange>
          <Description>
          Collaborated with township officials and the mayor to develop <Highlight>inclusive public policies</Highlight> addressing equity and diversity.<br /><br />
          Led workshops and community programs promoting cultural awareness and supporting underrepresented groups in local education and business sectors.<br /><br />
          Contributed to a community-driven initiative to improve inclusivity and strengthen local connections.
          </Description>
        </ExperienceDetails>
      </ExperienceCard>

      <ExperienceCard
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <Icon>
          <FontAwesomeIcon icon={faGavel} />
        </Icon>
        <ExperienceDetails>
          <CompanyName>President, HEALyu</CompanyName>
          <DateRange>2018 - Present</DateRange>
          <Description>
          Championed mental health advocacy by integrating data analytics into district-wide SEL programs, presenting insights to educational leaders and policymakers.<br /><br />
          Collaborated with Assemblyman Dan Benson to support Bill A359, advocating for enhanced student mental health policies.<br /><br />
          Organized panels and conferences, including presenting at the NJ Suicide Prevention Conference, impacting 100+ educators and administrators biannually.<br /><br />
          Partnered with organizations such as NAMI, AIR, and NJAMHAA to create multimedia resources and drive awareness campaigns.
          </Description>
        </ExperienceDetails>
      </ExperienceCard>

      <ExperienceCard
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <Icon>
          <FontAwesomeIcon icon={faCode} />
        </Icon>
        <ExperienceDetails>
          <CompanyName>Real World Data Analytics Application Projects</CompanyName>
          <DateRange>2019 - Present</DateRange>
          <Description>
            Completed extensive data analysis projects including **PIMA Diabetes Analyses**, **Movie Recommendation Systems**, and **Predictive Modeling for Temporal Data**. Used advanced regression, networking, and recommendation algorithms to solve real-world problems in various industries, consistently achieving high levels of accuracy and actionable insights.
          </Description>
        </ExperienceDetails>
      </ExperienceCard>

      <ExperienceCard
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <Icon>
          <FontAwesomeIcon icon={faBriefcase} />
        </Icon>
        <ExperienceDetails>
          <CompanyName>USA Computing Olympiad Silver Division (Perfect Score)</CompanyName>
          <DateRange>January 2024</DateRange>
          <Description>
            Achieved a perfect score in the USA Computing Olympiad Silver Division, demonstrating exceptional algorithmic and problem-solving skills in a highly competitive, national-level competition.
          </Description>
        </ExperienceDetails>
      </ExperienceCard>

      <ExperienceCard
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <Icon>
          <FontAwesomeIcon icon={faBrain} />
        </Icon>
        <ExperienceDetails>
          <CompanyName>Columbia University Science Honors Program</CompanyName>
          <DateRange>November 2023 - Present</DateRange>
          <Description>
            Participated in Columbia University’s exclusive Science Honors Program, focusing on advanced research in **quantum computing** and **bioengineering**. Conducted original research on the application of nanotechnology in biomedical devices, presenting findings to an audience of Columbia alumni, mentors, and industry experts.
          </Description>
        </ExperienceDetails>
      </ExperienceCard>

      <ExperienceCard
        initial={{ opacity: 0, x: -50 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true }}
      >
        <Icon>
          <FontAwesomeIcon icon={faUsers} />
        </Icon>
        <ExperienceDetails>
          <CompanyName>WW-P POC Advocacy - Chair</CompanyName>
          <DateRange>2021 - Present</DateRange>
          <Description>
            Led the education committee for WW-P POC Advocacy, responsible for overseeing and implementing curriculum modifications to ensure cultural inclusivity and social awareness. Revised district goals for **DEI** (Diversity, Equity, and Inclusion) and led the planning of Cultural Heritage Month events for the entire district, impacting 150+ participants.
          </Description>
        </ExperienceDetails>
      </ExperienceCard>


    </ExperienceSection>
  );
};

export default Experience;
