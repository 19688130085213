// src/App.js
import React from 'react';
import TraditionalPortfolio from './components/TraditionalPortfolio';


function App() {
  return (
    <div>
      <TraditionalPortfolio />
    </div>
  );
}

export default App;
