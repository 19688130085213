import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const AboutSection = styled.section`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
  background-color: #f0f4f8;
`;

const AboutContainer = styled.div`
  text-align: left;
  max-width: 800px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #3498db;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
`;

const Highlight = styled.span`
  color: #3498db;
  font-weight: bold;
`;

const About = () => {
  return (
    <AboutSection id="about">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <AboutContainer>
          <Title>About Me</Title>
          <Paragraph>
            Hi, I'm <Highlight>Adyanth Ganesh</Highlight>, a driven and passionate high school senior 
            with a focus on the role played by technology, machine learning, and data science in mental health advocacy. My work spans impactful projects like 
            the development of an AI-driven mental health app and research in <Highlight>neuroscience</Highlight> and <Highlight>psychology</Highlight>. 
            I was fortunate enough to intern at the <Highlight>Metropolitan Museum of Art</Highlight>, where I integrated my 
            passion for tech with the arts and my cultural heritage.
          </Paragraph>
          <Paragraph>
            As a self-starter, I've completed programs at leading institutions such as <Highlight>MIT</Highlight>, 
            <Highlight>Google</Highlight>, and <Highlight>Columbia University</Highlight>. Through my projects, coursework, internships, and experience, I’ve been able to apply my 
            knowledge in fields such as machine learning, mental health, and financial trading algorithms.
          </Paragraph>
        </AboutContainer>
      </motion.div>
    </AboutSection>
  );
};

export default About;
