// src/components/TraditionalPortfolio.js
import React from 'react';

import Header from './Header';
import About from './About';
import Education from './Education';
import Experience from './Experience';
import Projects from './Projects';
import Skills from './Skills';
import Papers from './Papers';
import Contact from './Contact';

const TraditionalPortfolio = () => {
  return (
    <div>
      <Header />
      <About />
      <Education />
      <Experience />
      <Projects />
      <Skills />
      <Papers />
      <Contact />
    </div>
  );
};

export default TraditionalPortfolio;

