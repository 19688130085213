// src/components/Header.js
import React from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';

const Navbar = styled.nav`
  position: fixed;
  width: 100%;
  height: 70px;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-family: 'Poppins', sans-serif;
`;

const Logo = styled.h1`
  font-size: 1.5rem;
  color: #2980b9;
  font-weight: 600;
  margin: 0;
  cursor: pointer;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 25px;
`;

const NavLink = styled(Link)`
  cursor: pointer;
  color: #555;
  font-size: 1rem;
  transition: color 0.3s ease;
  padding: 20px 0;
  white-space: nowrap;
  text-overflow: ellipsis; /* Ensure long text doesn't break layout */

  &:hover {
    color: #2980b9;
  }
`;

const ResumeButton = styled.a`
  padding: 10px 15px;  /* Even padding to prevent stretching */
  border: 2px solid #2980b9;
  border-radius: 5px;
  color: #2980b9;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;  /* Ensure text is centered vertically */
  text-decoration: none;
  
  &:hover {
    background-color: #2980b9;
    color: white;
  }
`;

/*
const GameButton = styled(RouterLink)`
  padding: 10px 15px;
  border: 2px solid #2980b9;
  border-radius: 5px;
  color: #2980b9;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  text-decoration: none;
  
  &:hover {
    background-color: #2980b9;
    color: white;
  }
`;
*/

const Header = () => {
  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Navbar>
      <Logo onClick={handleLogoClick}>Adyanth Ganesh</Logo>
      <NavLinks>
        <NavLink to="about" smooth={true} duration={500}>About</NavLink>
        <NavLink to="education" smooth={true} duration={500}>Education</NavLink>
        <NavLink to="experience" smooth={true} duration={500}>Experience</NavLink>
        <NavLink to="projects" smooth={true} duration={500}>Projects</NavLink>
        <NavLink to="skills" smooth={true} duration={500}>Skills & Honors</NavLink>
        <NavLink to="papers" smooth={true} duration={500}>Papers & Publications</NavLink>
        <ResumeButton href="/assets/resume.pdf" target="_blank">Resume</ResumeButton>
        <NavLink to="contact" smooth={true} duration={500} style={{ marginRight: '30px' }}>
          Contact Me
        </NavLink>
      </NavLinks>
    </Navbar>
  );
};

export default Header;
